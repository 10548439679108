import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import {
  ProjectPageWidgetPresets,
  ProjectPageWidgetTabsArray,
} from './config/constants';
import { defaultHelpId } from '../../constants';
import { openDashboardProjects } from '../../utils/dashboardUtils';
import { setWidgetDesignTabs } from '../../utils/manifestUtils';

const setDesktopGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
) => {
  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Project_Page_Widget_Gfpp_Main_Action_1'),
      onClick: openDashboardProjects(editorSDK),
    })
    .set('mainAction2', {
      label: t('Project_Page_Widget_Gfpp_Main_Action_2'),
      actionType: 'design',
      actionId: '',
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', { actionId: 'openElementsPanel' })
    .set('help', { id: defaultHelpId });
};

const setMobileGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
) => {
  controllerBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('Project_Page_Widget_Gfpp_Main_Action_1'),
      onClick: openDashboardProjects(editorSDK),
    })
    .set('mainAction2', {
      behavior: 'HIDE',
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });
};

const setWidgetDesignPanel = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
  isEditorX: boolean,
) => {
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    const designBuilder = widgetDesignBuilder.set({
      title: t('Project_Page_Widget_Design_Panel_Title'),
      presetsTitle: t('Project_Page_Widget_Design_Panel_Preset_Title'),
    });

    const widgetDesignTabsBuilder = designBuilder.tabs();
    designBuilder.setPresets(ProjectPageWidgetPresets);
    setWidgetDesignTabs(
      widgetDesignTabsBuilder,
      ProjectPageWidgetTabsArray,
      t,
      isEditorX,
    );
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Project_Design_Panel_Page_Background'),
          })
          .groups()
          .set({
            roles: ['projectPageWidgetRoot', 'projectHeaderWidgetRoot'],
          });

        tabBuilder.addSection((sectionBuilder) => {
          sectionBuilder.set({
            state: 'active',
            category: 'fill',
            label: t('Project_Design_Panel_Page_Background'),
            priority: 1,
            roles: ['projectPageWidgetRoot', 'projectHeaderWidgetRoot'],
            states: ['regular'],
          });
        });
      })
      // commented till styleable buttons fix
      // .addTab((tabBuilder) => {
      //   tabBuilder
      //     .set({
      //       label: t('Project_Design_Panel_Header_Background'),
      //     })
      //     .groups()
      //     .set({
      //       roles: ['BG'],
      //     });
      // })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Project_Design_Panel_Navigation_Buttons'),
          })
          .groups()
          .set({
            roles: ['navigationButton'],
          });
      });
  });
};

export const getWidgetManifest: GetWidgetManifestFn = async (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  const { isEditorX } = flowAPI.environment;
  controllerBuilder.set({
    displayName: t('Widget_Name_Project_Page_Widget'),
    nickname: 'projectPageWidget',
  });
  controllerBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
  });
  setDesktopGFPP(controllerBuilder, editorSDK, t);
  setMobileGFPP(controllerBuilder, editorSDK, t);
  setWidgetDesignPanel(controllerBuilder, editorSDK, t, isEditorX);
};
